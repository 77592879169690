"use client"

import "./feedbackForm.styles.css"

import { useIsDesktop } from "@deltagreen/react-library"
import { captureException } from "@sentry/nextjs"
import dynamic from "next/dynamic"

import { api } from "@/app/trpc"
import { usePathname } from "@/i18n/routing"
import { useIsMounted } from "@/utils/hooks/useIsMounted"

const Popover = dynamic(
  () =>
    import("@typeform/embed-react").then((module) => ({
      default: module.Popover,
    })),
  {
    ssr: false,
  },
)

const typeformBaseConfig = {
  id: "yPnc967m",
  buttonColor: "#00515f",
  height: 700,
  customIcon: "https://cdn.deltagreen.cz/misc/comment_with_star.png",
}

function useTypeformConfig() {
  const isDesktop = useIsDesktop()
  if (!isDesktop) {
    return {
      ...typeformBaseConfig,
      buttonWidth: 30,
      buttonProps: {
        style: "top: 8px !important; right: 8px !important;",
      },
    }
  }

  return typeformBaseConfig
}

type Props = {
  userId: string | null
}

function AuthenticatedFeedbackForm(props: Props) {
  const pathname = usePathname()
  const inverterList = api.inverters.list.useQuery()
  const config = useTypeformConfig()

  if (inverterList.isLoading) {
    return null
  }

  if (inverterList.isError) {
    captureException(inverterList.error)
    return null
  }

  return (
    <Popover
      {...config}
      hidden={{
        url: pathname,
        user_id: props.userId,
        inverters: JSON.stringify(inverterList.data),
      }}
    />
  )
}

function UnauthenticatedFeedbackForm() {
  const pathname = usePathname()
  const config = useTypeformConfig()

  return (
    <Popover
      {...config}
      hidden={{
        url: pathname,
      }}
    />
  )
}

export function FeedbackForm(props: Props) {
  const isMounted = useIsMounted()
  if (!isMounted) {
    return null
  }

  if (props.userId) {
    return <AuthenticatedFeedbackForm {...props} />
  } else {
    return <UnauthenticatedFeedbackForm />
  }
}
